// jQueryをimport
import jquery from 'jquery';

// jQueryプラグイン対策: `$` や `jQuery` を利用している場合に
//   `$ is not defined`
//   `jQuery is not defined`
// と参照エラーが起こらないようにしておく
window.$ = window.jQuery = jquery;

import 'bootstrap';

import 'intl';
